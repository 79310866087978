.icon-airplane {
  width: 3.72em;
  height: 1em;
  fill: none;
}
.icon-arrow-right {
  width: 0.6em;
  height: 1em;
  fill: #01A9F4;
}
.icon-camera {
  width: 1em;
  height: 1em;
  fill: #01A9F4;
}
.icon-case-star {
  width: 1.07em;
  height: 1em;
  fill: #F54236;
}
.icon-case {
  width: 1.1em;
  height: 1em;
  fill: #01A9F4;
}
.icon-comment {
  width: 1em;
  height: 1em;
  fill: #F54236;
}
.icon-fb {
  width: 1em;
  height: 1em;
  fill: #303030;
}
.icon-finger {
  width: 0.9em;
  height: 1em;
  fill: #FFF;
}
.icon-flower {
  width: 0.85em;
  height: 1em;
  fill: #607D8B;
}
.icon-learning {
  width: 0.97em;
  height: 1em;
  fill: #F54236;
}
.icon-menu {
  width: 1.53em;
  height: 1em;
  fill: #EE4438;
}
.icon-people {
  width: 1.43em;
  height: 1em;
  fill: #F54236;
}
.icon-plane {
  width: 0.96em;
  height: 1em;
  fill: #F54236;
}
.icon-play {
  width: 0.69em;
  height: 1em;
  fill: #FFF;
}
.icon-spam {
  width: 0.87em;
  height: 1em;
  fill: #F54236;
}
.icon-tw {
  width: 1.24em;
  height: 1em;
  fill: #303030;
}
