@mixin d {
  @media only screen and (max-width: "1120px") {
    @content; } }
@mixin t {
  @media only screen and (max-width: "1023px") {
    @content; } }
@mixin m {
  @media only screen and (max-width: "767px") {
    @content; } }
@mixin s {
  @media only screen and (max-width: "474px") {
    @content; } }

// +font(, )
// =fl
//   font-family: '', serif

@mixin pn {
  font-family: 'proxima-nova', Helvetica Neue, Helvetica, sans serif; }

$black: #000;
$white: #fff;
$red: #F54236;
$blue: #01A9F4;
$gray: #607D8B;
$border: rgba(#000,.1);
$cl: #202020;

body {
  @include pn;
  font-size: 14px;
  color: $cl;
  line-height: 1.3;
  &.no-scroll {
    overflow: hidden; } }
body,
button,
input,
textarea,
select {
  @include pn; }
a {
  text-decoration: none; }
input[type="text"],
textarea {
  -webkit-appearance: none; }

.m-show {
  display: none;
  @include m {
    display: block; } }
.m-hide {
  @include m {
    display: none; } }

.center {
  width: 100%;
  max-width: 1120px + 90px;
  margin: 0 auto;
  padding: 0 45px;
  @include m {
    padding: 0 15px; }
  &_sm {
    max-width: 730px + 90px; }
  &_lg {
    max-width: 100%; } }

.btn {
  height: 50px;
  padding: 0 22px;
  border-radius: 25px;
  background: $red;
  font-size: 20px;
  color: #fff;
  transition: opacity .2s;
  &:hover {
    opacity: .8; } }

.content {
  h1 {
    margin-bottom: 25px;
    @include fo(54,57);
    font-weight: 300;
    letter-spacing: -1.5px;
    @include m {
      margin-bottom: 20px;
      @include fo(43,45);
      letter-spacing: -1px; } }
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 14px;
    @include fo(25,36);
    font-weight: 400;
    @include m {
      margin-bottom: 19px; } }
  p {
    margin-bottom: 15px;
    opacity: .6;
    @include fo(20,25);
    @include m {
      margin-bottom: 10px;
      @include fo(20,28); } }
  p {
    & + h1,
    & + h2,
    & + h3,
    & + h4,
    & + h5,
    & + h6 {
      margin-top: 44px;
      @include m {
        margin-top: 31px; } } } }

.header {
  position: relative;
  padding: 25px 0;
  z-index: 10;
  @include m {
    padding: 15px 0; }
  &__center {
    display: flex;
    align-items: center; }
  &__logo {
    font-size: 0;
    transition: opacity .2s;
    &:hover {
      opacity: .8; }
    .icon {
      font-size: 36px;
      fill: $red; } }
  &__hamburger {
    display: none;
    width: 55px;
    height: 45px;
    margin-left: auto;
    border: 1px solid rgba($red,.5);
    border-radius: 3px;
    background: none;
    font-size: 0;
    .icon {
      font-size: 19px;
      fill: $red; }
    @include m {
      display: block; } }
  &__right {
    display: flex;
    margin-left: auto;
    align-items: center;
    @include m {
      display: none;
      position: absolute;
      top: 73px;
      left: 0;
      right: 0;
      padding-bottom: 10px;
      box-shadow: 0 2px 2px rgba(#000,.1);
      background: #FFF;
      flex-direction: column;
      &.visible {
        display: flex; } } }
  &__nav {
    display: flex;
    margin-right: 45px;
    @include m {
      width: 100%;
      margin: 0 0 10px;
      flex-direction: column; } }
  &__link {
    opacity: .6;
    font-size: 20px;
    color: $cl;
    transition: opacity .2s;
    @include m {
      display: block;
      padding: 15px;
      border-bottom: 1px solid $border;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      text-align: center;
      &:first-child {
        border-top: 1px solid $border; } }
    &:hover {
      opacity: 1; }
    @include nl {
      margin-right: 42px;
      @include m {
        margin: 0; } } } }

.main {
  padding: 207px 0 214px;
  background-position: 50% 50%;
  background-size: cover;
  color: #FFF;
  @include m {
    padding: 59px 0 66px; }
  &__wrap {
    max-width: 560px; }
  &__icon {
    display: inline-flex;
    width: 100px;
    height: 100px;
    margin-bottom: 39px;
    border-radius: 18px;
    background: linear-gradient(to top right, #FF2F65, #FF5C9F);
    font-size: 0;
    align-items: center;
    justify-content: center;
    @include m {
      margin-bottom: 36px; }
    .icon {
      font-size: 68px;
      fill: #fff; } }
  &__title {
    margin-bottom: 12px;
    @include fo(54,57);
    font-weight: 600;
    letter-spacing: -1.5px;
    @include m {
      margin-bottom: 15px;
      @include fo(43,45);
      letter-spacing: -1px; } }
  &__text {
    margin-bottom: 30px;
    @include fo(26,36);
    @include m {
      margin-bottom: 21px; } }
  &__note {
    margin-bottom: 38px;
    opacity: .7;
    @include fo(20,28); }
  &_download {
    padding: 128px 0;
    text-align: center;
    @include m {
      padding: 57px 0 64px; } }
  &_download &__wrap {
    max-width: 740px;
    margin: 0 auto; }
  &_download &__title {
    margin-bottom: 8px;
    font-weight: 400;
    @include m {
      margin-bottom: 16px; } }
  &_download &__download {
    align-self: center; } }

.download {
  display: inline-flex;
  @include m {
    flex-direction: column; }
  &__item {
    width: 173px;
    transition: opacity .2s;
    &:hover {
      opacity: .8; }
    @include nl {
      margin-right: 15px;
      @include m {
        margin: 0 0 15px; } } }
  &__pic {
    display: block;
    width: 100%; } }

.link {
  display: inline-flex;
  align-items: center;
  color: $blue;
  fill: $blue;
  transition: opacity .2s;
  &:hover {
    opacity: .8; }
  &:hover &__icon {
    transform: translatex(7px); }
  &__title {
    @include fo(14,28);
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px; }
  &__icon {
    margin-left: 10px;
    padding-bottom: 2px;
    font-size: 0;
    transition: transform .2s;
    .icon {
      font-size: 12px;
      fill: inherit; } } }

.quote {
  display: flex;
  &__preview {
    height: 55px;
    margin-right: 25px;
    flex: 0 0 55px;
    @include m {
      margin-right: 15px; } }
  &__pic {
    width: 100%; }
  &__head {
    margin-bottom: 5px;
    @include fo(17,22);
    @include m {
      min-height: 55px;
      margin-bottom: 10px;
      padding: 5px 0; } }
  &__name {
    font-weight: 600; }
  &__text {
    opacity: .6;
    @include fo(17,24);
    font-style: italic;
    @include m {
      margin-left: -70px; } } }

.stats {
  display: flex;
  text-align: center;
  @include t {
    flex-direction: column; }
  &__col {
    color: $red;
    &:last-child {
      @include t {
        margin: 0;
        padding: 20px;
        border: 1px solid rgba(#1A1A1A,.1); } }
    @include nl {
      margin-right: 36px;
      padding-right: 36px;
      border-right: 1px solid rgba(#000,.15);
      @include t {
        margin: 0;
        padding: 20px;
        border: 1px solid rgba(#1A1A1A,.1);
        border-width: 1px 1px 0 1px; } } }
  &__title {
    @include fo(50,55);
    font-weight: 300; }
  &__value {
    @include fo(17,24); } }


.iphone {
  width: 307px;
  height: 615px;
  background: url(../img/iphone.png) no-repeat 0 0 / contain;
  @include t {
    width: 290px;
    height: 582px; }
  &__screen {
    padding: 65px 23px 0 21px;
    @include t {
      padding: 60px 20px 0 22px; } }
  &__pic {
    display: block;
    width: 100%;
    height: auto; } }

.play {
  display: block;
  width: 90px;
  height: 90px;
  margin: 0 auto;
  padding-left: 1%;
  border-radius: 50px;
  border: 3px solid #fff;
  background: rgba(#0F0F0F,.8);
  font-size: 0;
  transition: opacity .2s;
  &:hover {
    opacity: .8; }
  .icon {
    font-size: 36px; } }

.item {
  color: $cl;
  fill: $blue;
  text-align: center;
  &__icon {
    display: flex;
    height: 40px;
    margin-bottom: 25px;
    align-items: center;
    justify-content: center;
    .icon {
      font-size: 40px;
      fill: inherit; }
    .icon-learning {
      font-size: 38px; }
    .icon-case {
      font-size: 36px; }
    .icon-span {
      font-size: 39px; } }
  &__title {
    margin-bottom: 2px;
    @include fo(14,28);
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: .93px; }
  &__text {
    opacity: .6;
    @include fo(18,24); }
  &_feature {
    fill: $red; }
  &_feature &__icon {
    height: 60px;
    margin-bottom: 36px;
    .icon-camera {
      font-size: 60px; }
    .icon-case {
      font-size: 54px; }
    .icon-spam {
      font-size: 59px; }
    .icon-learning {
      font-size: 57px; }
    .icon-finger {
      font-size: 56px; }
    .icon-plane {
      font-size: 55px; }
    .icon-people {
      font-size: 56px; }
    .icon-comment {
      font-size: 60px; } }
  &_question &__title {
    margin-bottom: 8px;
    @include fo(18,24);
    text-transform: none;
    letter-spacing: 0;
    @include m {
      margin-bottom: 12px; } } }

.block {
  max-width: 450px;
  fill: $blue;
  &__head {
    margin-bottom: 19px;
    @include fo(14,28);
    color: $blue;
    font-weight: 600;
    letter-spacing: .93px;
    text-transform: uppercase; }
  &__icon {
    display: inline-flex;
    width: 60px;
    height: 60px;
    margin-bottom: 36px;
    border-radius: 50%;
    border: 2px solid;
    align-items: center;
    justify-content: center;
    color: $blue;
    @include m {
      margin-bottom: 22px; }
    .icon {
      fill: inherit; }
    .icon-finger,
    .icon-flower {
      font-size: 34px; }
    .icon-spam {
      font-size: 31px; }
    .icon-learning {
      font-size: 30px; }
    .icon-case {
      font-size: 29px; }
    .icon-case-star {
      font-size: 28px; } }
  &__title {
    margin-bottom: 13px;
    @include fo(30,36);
    font-weight: 600;
    letter-spacing: -1px; }
  &__text {
    margin-bottom: 16px;
    opacity: 0.6;
    @include fo(20,28);
    @include m {
      margin-bottom: 14px; } }
  &__note {
    margin-top: 32px;
    opacity: .4;
    @include fo(15,20);
    @include m {
      margin-top: 15px; } }
  &__quote {
    margin-top: 32px;
    @include m {
      margin-top: 23px;
      padding-top: 18px;
      border-top: 1px solid $border; } }
  &__stats {
    margin-top: 41px;
    @include m {
      margin-top: 30px; } }
  &__play {
    margin-top: 71px;
    @include m {
      margin-top: 44px; } }
  &__info {
    display: flex;
    margin-top: 30px;
    @include m {
      margin-top: 26px;
      flex-direction: column; }
    &-item {
      text-align: left;
      &:first-child {
        padding-right: 20px;
        @include m {
          padding: 0 0 22px; } } } }
  &_gray &__icon,
  &_gray &__link {
    color: $gray;
    fill: $gray; }
  &_red &__icon,
  &_red &__link {
    color: $red;
    fill: $red; }
  &_white,
  &_white_content, {
    color: #FFF; }
  &_white &__text,
  &_white_content &__text {
    @include smooth-font; }
  &_white &__icon,
  &_white &__link {
    color: #fff;
    fill: #fff; } }

.section {
  padding: 90px 0;
  border-bottom: 1px solid rgba(#000,.1);
  @include m {
    padding: 45px 0; }
  &__head {
    margin-bottom: 47px;
    text-align: center;
    @include m {
      margin-bottom: 30px;
      text-align: left; } }
  &__title {
    margin-bottom: 23px;
    @include fo(54,57);
    font-weight: 600;
    letter-spacing: -1.5px;
    @include m {
      margin-bottom: 14px;
      @include fo(43,45);
      letter-spacing: -1px; } }
  &__text {
    opacity: .6;
    @include fo(20,28); }
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include m {
      flex-direction: column; } }
  &__col {
    &:last-child {
      padding: 0 7%;
      @include t {
        padding: 0 0 0 40px; }
      @include m {
        padding: 32px 0 0 0; } } }
  &__atom {
    margin-top: 62px; }
  &__tweets {
    margin-top: 47px; }
  &__content {
    max-width: 730px;
    margin: 0 auto; }
  &__form {
    margin-top: 48px; }
  &_first {
    padding-top: 125px; }
  &_inverse &__col {
    &:nth-child(1) {
      order: 2; }
    &:nth-child(2) {
      order: 1;
      @include t {
        padding: 0 40px 0 0; }
      @include m {
        padding: 0 0 32px 0; } } }
  &_centering &__block {
    max-width: 730px;
    margin: 0 auto;
    text-align: center;
    @include m {
      text-align: left; } }
  &_article {
    padding: 94px 0 564px;
    background: #f1f1f1 url(../img/bg-article.jpg) no-repeat 50% 100%;
    @include m {
      padding: 42px 0 330px;
      background-size: 340% auto;
      background-color: #ededed; } }
  &_trip {
    padding: 200px 0;
    background: #f1f1f1 url(../img/bg-trip.jpg) no-repeat 50% 50% / cover;
    @include m {
      padding: 170px 0 175px;
      background-size: auto 100%;
      background-position: 56% 50%; } }
  &_video &__block,
  &_find &__block {
    max-width: 540px; }
  &_video {
    padding: 128px 0 177px;
    background: #f1f1f1 url(../img/bg-video.jpg) no-repeat 50% 50% / cover;
    @include m {
      padding-top: 54px; } }
  &_video &__block {
    @include m {
      text-align: center; } }
  &_find {
    padding: 260px 0 230px;
    background: #f1f1f1 url(../img/bg-find.jpg) no-repeat 50% 50% / cover;
    @include m {
      padding: 109px 0 105px; } }
  &_questions &__info {
    margin-top: 58px;
    @include m {
      margin-top: 34px; } }
  &_contact &__head {
    text-align: left; }
  &_contact &__title {
    font-weight: 300; } }

.social {
  display: flex;
  @include m {
    margin: 0 -15px;
    border-bottom: 1px solid $border; }
  &__item {
    opacity: .8;
    font-size: 0;
    transition: opacity .2s;
    @include m {
      display: flex;
      height: 49px;
      flex-basis: 50%;
      align-items: center;
      justify-content: center;
      @include nl {
        border-right: 1px solid $border; } }
    &:hover {
      opacity: 1; }
    @include nl {
      margin-right: 28px;
      @include m {
        margin: 0; } }
    .icon {
      font-size: 17px; } } }

.footer {
  padding: 50px 0;
  border-to: 1px solid rgba(#303030,.1);
  @include m {
    padding: 0 0 27px; }
  &__row {
    display: flex;
    margin-bottom: 15px;
    align-items: center;
    justify-content: center;
    @include m {
      display: block;
      margin-bottom: 26px; } }
  &__nav {
    @include m {
      margin: 0 -15px; } }
  &__link {
    margin-right: 28px;
    opacity: .8;
    @include fo(14,28);
    font-weight: 600;
    color: $cl;
    transition: opacity .2s;
    @include m {
      display: block;
      width: 100%;
      margin: 0;
      padding: 10px 15px;
      border-bottom: 1px solid $border;
      text-align: center; }
    &:hover {
      opacity: 1; } }
  &__copyright {
    opacity: .6;
    @include fo(15,20);
    text-align: center; } }

.atom {
  display: flex;
  @include m {
    flex-direction: column; }
  &__left,
  &__right {
    display: flex;
    flex-direction: column; }
  &__item {
    display: flex;
    flex: 1 0 50%;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  &__left &__item {
    @include m {
      margin-bottom: 39px; } }
  &__right &__item {
    @include m {
      margin-top: 39px; } }
  &__center {
    margin: 0 9%;
    flex: 0 0 307px;
    @include m {
      margin: 0;
      flex: 0 0 100%; } }
  &__iphone {
    @include m {
      margin: 0 auto; } } }

.tweets {
  display: flex;
  margin-left: -28px;
  @include m {
    margin: 0;
    flex-direction: column; }
  &__col {
    max-width: calc(33.33% - 28px);
    margin-left: 28px;
    flex: 0 0 calc(33.33% - 28px);
    @include m {
      max-width: 100%;
      margin: 0;
      flex: 0 0 100%; } }
  twitterwidget {
    width: 100%; } }

.grid {
  display: flex;
  margin: 0 0 -28px -28px;
  flex-wrap: wrap;
  @include m {
    margin: 0; }
  &__item {
    margin: 0 0 28px 28px;
    padding: 41px 25px 43px;
    border: 1px solid rgba(#303030,.1);
    flex: 0 0 calc(33.33% - 28px);
    transition: border-color .2s;
    @include m {
      margin: 0;
      padding: 31px 15px 28px;
      flex: 0 0 100%;
      @include nl {
        margin-bottom: 15px; } } }
  &_two &__item {
    flex: 0 0 calc(50% - 28px);
    @include m {
      flex: 0 0 100%; } }
  a.grid__item {
    &:hover {
      border-color: rgba($red,.5); } } }

.info {
  &__title {
    margin-bottom: 29px;
    @include fo(30,36);
    font-weight: 600; }
  &__list {
    display: flex;
    margin: 0 -35px -39px;
    flex-wrap: wrap;
    @include m {
      margin: 0; } }
  &__item {
    margin: 0 35px 39px;
    flex: 0 0 calc(33.33% - 70px);
    text-align: left;
    @include m {
      margin: 0;
      flex: 0 0 100%;
      @include nl {
        margin-bottom: 17px; } } }
  &_questions &__item {
    @include m {
      @include nl {
        margin-bottom: 28px; } } } }

.help {
  margin-top: -100px;
  padding: 208px 0 131px;
  background: #FFEFE5;
  @include m {
    margin-top: -75px;
    padding: 116px 0 47px; }
  &__center {
    max-width: 920px + 90px;
    text-align: center; }
  &__title {
    margin-bottom: 43px;
    @include fo(54,57);
    font-weight: 300;
    letter-spacing: -1.5px;
    color: $red;
    @include m {
      margin-bottom: 34px;
      @include fo(43,54);
      letter-spacing: -1px; } }
  &__search {
    margin-bottom: 37px;
    @include m {
      margin-bottom: 28px; } }
  &__input {
    width: 100%;
    height: 72px;
    padding: 0 25px;
    font-size: 20px; }
  &__example {
    @include fo(20,25);
    color: rgba($cl,.6);
    @include m {
      @include fo(20,28); } }
  &__query {
    font-weight: 600;
    color: $cl; } }

.form {
  &__row {
    display: flex;
    @include m {
      flex-direction: column; } }
  &__row &__field {
    flex: 0 0 50%;
    @include m {
      flex: 0 0 100%; }
    &:first-child {
      border-right: 2px solid #fff;
      @include m {
        border: none; } } }
  &__field {
    margin-bottom: 24px;
    @include m {
      margin-bottom: 22px; } }
  &__label {
    margin-bottom: 5px;
    @include fo(14,28);
    font-weight: 600;
    letter-spacing: .93px; }
  &__input {
    width: 100%;
    height: 56px;
    padding: 0 18px;
    background: #F5F5F5;
    font-size: 20px; }
  &__area {
    width: 100%;
    height: 167px;
    padding: 16px 19px;
    background: #F5F5F5;
    border: none;
    @include fo(18,24);
    color: rgba($cl,.6); }
  &__btn {
    margin-top: 4px;
    height: 56px;
    padding: 0 20px;
    border-radius: 0;
    font-size: 14px;
    letter-spacing: .93px;
    text-transform: uppercase;
    @include m {
      width: 100%;
      margin: 0; } } }





